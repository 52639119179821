import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import GoTrue from "gotrue-js"

const defaultState = {
  isLoggedIn: false,
  setUser: () => {},
  getUser: () => {},
  handleLogin: () => {},
  handleSignup: () => {},
  requestPasswordRecovery: () => {},
  handleConfirmation: () => {},
  handleInvitation: () => {},
  handleReset: () => {},
  handlePasswordReset: () => {},
  logout: () => {},
}

const apiEndpoint = "https://www.gba-group.com/.netlify/identity"
const auth = new GoTrue({
  APIUrl: apiEndpoint,
})

const ShopContext = React.createContext(defaultState)

const ShopProvider = ({ children }) => {
  const setUser = (user) =>
    (window.localStorage.shopUser = JSON.stringify(user))
  const getUser = () =>
    window.localStorage.shopUser ? JSON.parse(window.localStorage.shopUser) : {}

  const [isLoggedIn, setIsLoggedIn] = useState(!!getUser().email)

  const handleLogin = (email, password, callback) => {
    auth
      .login(email, password)
      .then(() => {
        setUser(auth.currentUser())
        setIsLoggedIn(true)
        callback(true, "Your are logged in")
      })
      .catch((error) => {
        setIsLoggedIn(false)
        callback(false, error.json.error_description)
      })
  }

  const handleSignup = (email, password, callback) => {
    auth
      .signup(email, password)
      .then((response) => {
        setUser(response)
        callback(true, "Signup successful")
      })
      .catch((error) => {
        return callback(false, error.json.msg)
      })
  }

  const requestPasswordRecovery = (email, callback) => {
    auth
      .requestPasswordRecovery(email)
      .then((response) => {
        callback(true, "Recovery email sent")
      })
      .catch((error) => {
        return callback(false, error.json.msg)
      })
  }

  const handleConfirmation = (token, callback) => {
    auth
      .confirm(token, true)
      .then(function (response) {
        setUser(response)
        callback(true, "Thanks for confirming, Please reset you Password")
      })
      .catch(function (error) {
        return callback(false, error.json.msg)
      })
  }

  const handleInvitation = (token, password, callback) => {
    auth
      .acceptInvite(token, password, true)
      .then(function (response) {
        setUser(response)
        setIsLoggedIn(true)
        callback(true, "Thanks for confirming, Password is set")
      })
      .catch(function (error) {
        let message = error.json.msg
        return callback(false, message)
      })
  }

  const handleReset = (token, callback) => {
    auth
      .recover(token)
      .then((response) => {
        setUser(response)
        setIsLoggedIn(true)
        callback(true, "Reset your password")
      })
      .catch(function (error) {
        let message = error.json.msg
        return callback(false, message)
      })
  }

  const handlePasswordReset = (password, callback) => {
    const user = auth.currentUser()
    const email = user.email

    user
      .update({ email, password })
      .then(() => {
        callback("Password Updated")
      })
      .catch(function (error) {
        return callback(error.json.msg)
      })
  }

  const logout = () => {
    const user = auth.currentUser()

    if (user) {
      user.logout().then(() => {
        setIsLoggedIn(false)
        setUser({})
      })
    } else {
      setIsLoggedIn(false)
      setUser({})
    }
  }

  useEffect(() => {
    const user = getUser()

    if (isLoggedIn) {
      const currentUser = auth.currentUser()
      if (!currentUser) {
        setUser({})
        setIsLoggedIn(false)

        return
      }

      currentUser
        .jwt()
        .then(() => {
          setIsLoggedIn(!!user.email)
        })
        .catch(() => {
          setIsLoggedIn(false)
          setUser({})
        })
    }
  }, [isLoggedIn])

  return (
    <ShopContext.Provider
      value={{
        isLoggedIn,
        setUser,
        getUser,
        handleLogin,
        handleSignup,
        requestPasswordRecovery,
        handleConfirmation,
        handleInvitation,
        handleReset,
        handlePasswordReset,
        logout,
      }}
    >
      {children}
    </ShopContext.Provider>
  )
}

ShopProvider.propTypes = {
  children: PropTypes.node,
}

export default ShopContext

export { ShopProvider }
